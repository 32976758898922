import React from "react";
import { Tile } from "./Tile"
import { Button } from "./";

export class TileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      num: 8
    };
  }

  render() {
    let tiles = [];
    for (let i = 0; i < this.state.num; i++) {
      tiles.push(
      <Tile 
        id={i + 1} 
        onSelectTexture={this.props.onSelectTexture}/>
      );
    }

    return (
      <div style={{width: "100%", maxWidth: "1230px", margin: "auto", backgroundColor: "black", borderRadius: "20px"}}>
        <div style={{display: "flex", flexWrap: 'wrap', width: "100%"}}>
          {tiles}
        </div>
        <p style={{padding: "20px", textAlign: "center"}}>
        👆 Tap
        </p>

        {/* <Button  
          style={{margin: "20px 40px"}}
          onClick={() => this.setState({ num: this.state.num + 12 })}>
            Generate More Patterns
        </Button> */}
      </div>
    );
  }
}
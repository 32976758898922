import React, { useRef, useState, Suspense } from "react";
import { Canvas, useFrame, useLoader, Box } from '@react-three/fiber';
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import * as THREE from 'three';

const shapes = {
  "box": {
    element: <boxBufferGeometry args={[1, 1, 1]} attach="geometry"/>,
    cameraPosition: [0, 0, 1.75],
    rotation: [0.5, 0.5, 0],
    scale: [1, 1, 1],
    next: "sphere"
  },
  "sphere": {
    element: <sphereBufferGeometry attach="geometry"/>,
    cameraPosition: [0, 0, 2.5],
    rotation: [0.5, 0.5, 0],
    scale: [0.7, 0.7, 0.7],
    next: "octahedron"
  },
  "octahedron": {
    element: <octahedronBufferGeometry attach="geometry"/>,
    cameraPosition: [0, 0, 2.5],
    rotation: [0.2, 0.5, 0],
    scale: [0.75, 0.75, 0.75],
    next: "box"
  }
}

export class Render extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shape: "box"
    };
  }

  changeShape() {
    this.setState({shape: shapes[this.state.shape].next})
  }

  Box(props) {
    const mesh = useRef()
    // const [state, setState] = useState({isHovered: false, isActive: false})
    useFrame(() => (
      mesh.current.rotation.y += 0.005
      ))
    let texture = useLoader(TextureLoader, String(props.texture));
    texture.encoding = THREE.sRGBEncoding;

    return (
      <mesh
        {...props}
        ref={mesh}
        rotation={shapes[props.state.shape].rotation}
        scale={shapes[props.state.shape].scale}>
        {/* <sphereBufferGeometry attach="geometry"/> */}
        {shapes[props.state.shape].element}
        <meshBasicMaterial map={texture} needsUpdate={true}/>
      </mesh>
    )
  }

  render() {
    return (
      <Canvas 
        style={{cursor: "pointer", height: "300px", width: "300px", position: "fixed", top: "100px", right: "100px", backgroundColor: "transparent"}}
        camera={{ position: shapes[this.state.shape].cameraPosition, fov: 60 }}>
        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />
        <Suspense fallback={null}>
          <this.Box onClick={() => this.changeShape()} texture={this.props.texture} position={[0, 0, 0]} state={this.state}/>
        </Suspense>
      </Canvas>
    );
  }
}
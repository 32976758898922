import React from "react";
import { TileContainer } from "./TileContainer";
import { Render } from "./Render";
import { Examples } from "./Examples";
import { img } from "./default64";
import { Button } from "./";
import { generate } from "../hooks/generate";

export class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 1,
      texture: img
    };
  }

  handleTexture = (texture) => {
    // this.setState({texture: texture});
    let trim = String(texture).substr(5, String(texture).length - 7);
    this.setState({texture: trim})
  }

  render() {
    return (
      <div>
       {/* <Image src={logo} alt="react-logo" /> */}
       <div>
        <h2><b>
        MULTIVERSALS
        </b></h2>
          {/* Remove the "hidden" prop and open the JavaScript console in the browser to see what this function does */}
          <div style={{maxWidth: "700px", fontSize: "1.2em"}}>
            <br />
            <p>
            NFT patterns that can be <b>applied</b>.
            </p>
            <p>
              Protocols to enable NFT texture mapping in any universe, virtual or physical. A repeatable tile texture can be applied to any 3D mesh.
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />

            {/* <p>
              NFT's can be more than self contained items. Multiversals can be used across different spaces, and add continuous personality wherever you are.
            </p> */}
            {/* <p>
              Multiversals (v1) are algorithmically generated patterns. They are tilable, and be used as a baseline for developing global, multiverse texture mapping and texture ownership protocols.
            </p> */}
            <br />
          </div>
        </div>

        <Render texture={this.state.texture} />
        <TileContainer onSelectTexture={this.handleTexture}/>
        <Examples />


        <p>
          _____________________________________________________________________
        </p>
        <br />
        <p>
          Built by [ajitama.eth]
        </p>
        {/* <Button  
          style={{margin: "20px 40px"}}
          onClick={() => generate()}>
            Generate All
        </Button> */}
      </div>
    );
  }
}
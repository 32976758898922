import React from "react";
var GeoPattern = require('geopattern');

export class Tile extends React.Component {
  render() {

    const rndInt = Math.floor(Math.random() * 10001) + 1

    let pattern = GeoPattern.generate(String(rndInt));
    // let pattern = GeoPattern.generate(String(this.props.id));
    // let pattern = GeoPattern.generate(this.props.id);
    let imgData = pattern.toDataUrl(); // url("data:image/svg+xml;...

    return (
      <div style={{maxWidth: "300px", minWidth: "300px"}}>
        <div style={{marginLeft: "40px"}}>
          {/* <p style={{ width: "238px", marginBottom: 0, paddingBottom: "10px", padding: "6px", backgroundColor: "rgba(0,0,0,0.15)"}}>
            ID: {this.props.id}
          </p> */}
          <div 
            style={{cursor: "pointer", height: "250px", width: "250px", marginTop: "30px", backgroundImage: imgData, borderRadius: "12px"}} 
            onClick={() => this.props.onSelectTexture(imgData)}/>
        </div>
      </div>
    );
  }
}
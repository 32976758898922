var GeoPattern = require('geopattern');

function saveSvg(svgEl, name) {
  var preface = '<?xml version="1.0" standalone="no"?>\r\n';
  var svgBlob = new Blob([preface, svgEl], {type:"image/svg+xml;charset=utf-8"});
  var svgUrl = URL.createObjectURL(svgBlob);
  var downloadLink = document.createElement("a");
  downloadLink.href = svgUrl;
  downloadLink.download = name;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, ms)
  })
}

export async function generate() {
  for(let i = 1; i < 10001; i++) {
    let index = String(i);
    let pattern = GeoPattern.generate(index);
    // let pattern = GeoPattern.generate(this.props.id);
    let imgData = pattern.toDataUrl(); // url("data:image/svg+xml;...
    let imgSvg = pattern.toSvg();
  
    saveSvg(imgSvg, index + ".svg");
    await delay(200);
  }

}
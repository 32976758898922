import React from "react";
const shirtGif = require('../assets/shirt.gif')
const shoeGif = require('../assets/shoe.gif')
const avatarGif = require('../assets/avatar.gif')
const roomGif = require('../assets/room.gif')

export class Examples extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   id: 1,
    //   texture: img
    // };
  }

  render() {
    return (
      <div>
        <br />
        <h2><b>
          Why?
        </b></h2>
        <div style={{maxWidth: "700px"}}>
          <p>
            A Multiversal NFT can be <i>applied</i> to other things. 
            <br />
            An expression of identity & personality, anywhere.
          </p>
          <p>
            Here are some uses:
          </p>
        </div>
        <p>
          _____________________________________________________________________
        </p>
        <h3><b>
          1) Virtual Texture Mapping
        </b></h3>
        <p>
          - A standard for swappable textures in 3D & 2D virtual environments.
          <br />
          - A standard for texture mappable NFTs.
          <br />
          - Ownership protocol to enable application of only your owned textures.
        </p>
        <img style={{ width: "400px", height: "400px", borderRadius: "60px" }}src={avatarGif} />
        <img style={{ width: "300px", height: "300px", margin: "50px", borderRadius: "20px" }}src={roomGif} />
        <p>
          _____________________________________________________________________
        </p>
        <h3><b>
          2) Physical Goods
        </b></h3>
        <p>
          - Verified custom printing of your textures.
          <br />
          - Standard to differentiate "owned" from "copied" physical goods.
          <br />
          - Partnerships and adoption by brands.
        </p>
        <img style={{ width: "400px", height: "400px" }}src={shirtGif} />
        <img style={{ width: "400px", height: "400px" }}src={shoeGif} />
      </div>
    );
  }
}